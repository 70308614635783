<template>
  <div>
    <!-- 轮播图-->
    <mySwiper></mySwiper>
    <div class="page_main_box">
      <div class="page_content_box">
        <!-- 下载区域 -->
        <div class="download_box">
          <div class="download_item">
            <img @click="download('https://itunes.apple.com/cn/app/id6596742969?mt=8')" src="../../public/images/home/ios.png" alt="">
          </div>
          <div class="download_item">
            <!-- <img src="../../public/images/home/Android.png" @click="download('https://wangyueoss1.oss-cn-hangzhou.aliyuncs.com/apk/dyUserApp.apk')"> -->
            <img src="../../public/images/home/Android.png" @click="download('https://oss.dianyuedaowei.com/apk/dyUserApp.apk')">
          </div>
        </div>
        <!-- 便捷生活 -->
        <div class="life_box" style="width: 100%">
          <div class="life_top">
            <div class="item_tit">便捷生活</div>
            <div class="item_subtitle">
              <div class="content">真实认证技师按摩SPA上门服务平台</div>
              <div class="line"></div>
            </div>
            <div class="life_feature">
              <div class="content">APP预约</div>
              <div class="line"></div>
              <div class="content">极速到位</div>
              <div class="line"></div>
              <div class="content">在家享受</div>
              <div class="line"></div>
              <div class="content">舒心按摩</div>
            </div>
          </div>
        </div>
        <div class="life_content">
          <div class="life_content_item">
            <img src="../../public/images/home/life1.png" alt="" />
            <div class="content_item_tit">时间自主</div>
            <div class="content_item_text">
              一键下单，在家享受按摩服务，不必再花费时间前往保健馆、医院等地，节约时间，随时安排时间进行按摩。
            </div>
          </div>
          <div class="life_content_item">
            <img src="../../public/images/home/life2.png" alt="" />
            <div class="content_item_tit">私人定制</div>
            <div class="content_item_text">
              在点约平台能够更容易挑选优质按摩师、上门推拿服务更加注重个性化需求，推拿师可以根据客户的肌肉组织情况、疼痛程度等综合因素灵活制定按摩方案和手法
            </div>
          </div>
          <div class="life_content_item" style="border-right: none">
            <img src="../../public/images/home/life3.png" alt="" />
            <div class="content_item_tit">时间自主</div>
            <div class="content_item_text">
              相对于推拿馆、医院等公共场所,点约上门推拿服务能够享受更舒适和私密放松的环境,给予你更多的关注和照顾
            </div>
          </div>
        </div>
        <!-- 功能特色 -->
        <div class="life_top">
          <div class="item_tit">功能特色</div>
          <div class="item_subtitle" style="margin-bottom: 80px">
            <div class="content">细节彰显品质、只为您的满意服务</div>
            <div class="line"></div>
          </div>
        </div>
        <!-- 功能特色第一行 -->
        <div class="features_box">
          <div class="features_item">
            <div class="features_item_left">
              <img src="../../public/images/home/features1.png" alt="" />
            </div>
            <div class="features_item_right">
              <div class="item_right_tit">邀好友福利</div>
              <div class="item_right_text">您的满意点赞</div>
              <div class="item_right_text">推荐给好友</div>
              <div class="item_right_text">立享推荐奖</div>
            </div>
          </div>
          <div class="features_item">
            <div class="features_item_left">
              <img src="../../public/images/home/features2.png" alt="" />
            </div>
            <div class="features_item_right">
              <div class="item_right_tit">技师任选</div>
              <div class="item_right_text">查看技师照片</div>
              <div class="item_right_text">个人信息</div>
              <div class="item_right_text">选择心仪技师</div>
            </div>
          </div>
          <div class="features_item">
            <div class="features_item_left">
              <img src="../../public/images/home/features3.png" alt="" />
            </div>
            <div class="features_item_right">
              <div class="item_right_tit">同城定位</div>
              <div class="item_right_text">精准定位</div>
              <div class="item_right_text">附近技师商家一目了然</div>
              <div class="item_right_text">预约到家轻松按摩</div>
            </div>
          </div>
        </div>
        <!-- 功能特色第二行 -->
        <div class="features_box" style="margin-top: 100px">
          <div class="features_item">
            <div class="features_item_left">
              <img src="../../public/images/home/features4.png" alt="" />
            </div>
            <div class="features_item_right">
              <div class="item_right_tit">真实认证技师</div>
              <div class="item_right_text">实名认证</div>
              <div class="item_right_text">持证上岗</div>
              <div class="item_right_text">超时秒退</div>
            </div>
          </div>
          <div class="features_item">
            <div class="features_item_left">
              <img src="../../public/images/home/features5.png" alt="" />
            </div>
            <div class="features_item_right">
              <div class="item_right_tit">即刻响应到位</div>
              <div class="item_right_text">让你足不出户</div>
              <div class="item_right_text">一键选人</div>
              <div class="item_right_text">无额外收费</div>
            </div>
          </div>
          <div class="features_item">
            <div class="features_item_left">
              <img src="../../public/images/home/features6.png" alt="" />
            </div>
            <div class="features_item_right">
              <div class="item_right_tit">全程平台保障</div>
              <div class="item_right_text">平台担保</div>
              <div class="item_right_text">明码标价</div>
              <div class="item_right_text">爽约包赔</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 80px">
      <!-- 项目模块 -->
      <div class="project_list_box">
        <div class="project_item_box">
          <div class="project_img">
            <img src="../../public/images/home/pro1.png" alt="" />
          </div>
          <div class="project_content">
            <div class="item_tit">法式 SPA</div>
            <div class="life_feature">
              <div class="item_subtitle">
                <div class="content">释放压力 改善睡眠</div>
                <div class="line"></div>
              </div>
            </div>
            <div class="project_text" style="width: 80%;">
              两小时的精油推拿经络，通过五感六觉与音乐全身心放松;配用迷人香氛相结合，安神养心，改善睡眠。
            </div>
            <div class="project_moreBtn">
              <img src="../../public/images/home/viewMore.png" alt="" />
            </div>
          </div>
        </div>
        <div class="project_item_box" style="flex-flow: row-reverse">
          <div class="project_img">
            <img src="../../public/images/home/pro2.png" alt="" />
          </div>
          <div class="project_content" style="margin-left: 1.5rem;">
            <div class="item_tit">非遗采耳</div>
            <div class="life_feature">
              <div class="item_subtitle">
                <div class="content">舒睛明目 静心助眠</div>
                <div class="line"></div>
              </div>
            </div>
            <div class="project_text">
              清洁耳部、促进耳部血液循环，可缓解神经、舒睛明目、静心助眠。
            </div>
            <div class="project_moreBtn">
              <img src="../../public/images/home/viewMore.png" alt="" />
            </div>
          </div>
        </div>
        <div class="project_item_box">
          <div class="project_img">
            <img src="../../public/images/home/pro4.png" alt="" />
          </div>
          <div class="project_content">
            <div class="item_tit">精品养生 SPA</div>
            <div class="life_feature">
              <div class="item_subtitle">
                <div class="content">调理气血 祛湿通络</div>
                <div class="line"></div>
              </div>
            </div>
            <div class="project_text" style="width: 80%;">
              以古法推拿为基础，配用天然植物精油,采取拉伸+细致手法按压，推揉肌肉和经络。蕴含着植物精油的温和滋润功效使身体各部位得到调理，促进全身血液循环，帮助身体新陈代谢。
            </div>
            <div class="project_moreBtn">
              <img src="../../public/images/home/viewMore.png" alt="" />
            </div>
          </div>
        </div>
        <div class="project_item_box" style="flex-flow: row-reverse">
          <div class="project_img">
            <img src="../../public/images/home/pro5.png" alt="" />
          </div>
          <div class="project_content" style="margin-left: 1.5rem;">
            <div class="item_tit">中式推拿</div>
            <div class="life_feature">
              <div class="item_subtitle">
                <div class="content">肩颈舒缓 消除疲惫</div>
                <div class="line"></div>
              </div>
            </div>
            <div class="project_text">
              根据中医原理结合中式传统推拿手法，通过问、切配以揉、点、压穴位，促进血液循环，舒筋络骨，缓解身体疲劳和腰肌劳损。
            </div>
            <div class="project_moreBtn">
              <img src="../../public/images/home/viewMore.png" alt="" />
            </div>
          </div>
        </div>
        <div class="project_item_box">
          <div class="project_img">
            <img src="../../public/images/home/pro3.png" alt="" />
          </div>
          <div class="project_content">
            <div class="item_tit">芳香精油</div>
            <div class="life_feature">
              <div class="item_subtitle">
                <div class="content">精油舒缓 舒筋活血</div>
                <div class="line"></div>
              </div>
            </div>
            <div class="project_text" style="width: 80%;">
              以掌、指推经络为主，让全身血脉畅通，臂腿经络及肩颈放松，配用怡人芳香精油滋养肌肤，促进肠道蠕动、补肾壮阳、燃烧脂肪。
            </div>
            <div class="project_moreBtn">
              <img src="../../public/images/home/viewMore.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <joinItem></joinItem> -->
  </div>
</template>

<script>
import joinItem from "@/component/joinItem.vue"
import mySwiper from "@/component/mySwiper.vue"
export default {
  components: { joinItem,mySwiper},
  name:"home",
  data() {
    return {
      timer: null,
      bannerH: 1200, //轮播图高度
    };
  },
  mounted() {
    // this.getBannerH();
  },
  computed: {
    size() {
      return this.$store.state.size;
    },
  },
  methods: {
    getBannerH() {
      this.timer = setTimeout(() => {
        this.bannerH = this.$refs.banner.clientHeight;
      }, 100);
    },
    download(url){
      window.location.href = url
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>

<style scoped>
.banner_box > img {
  width: 100%;
}
.page_main_box {
  width: 100%;
  display: flex;
  justify-content: center;
}
.page_content_box {
  width: 86%;
}
.life_top {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.item_tit {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 5px;
  color: #353638;
}
.item_subtitle {
  font-size: 10px;
  letter-spacing: 5px;
  margin: 8px 0;
  position: relative;
  color: #353638;
}
.item_subtitle > .line {
  width: 100%;
  height: 4px;
  background-color: #ffbf94;
  margin-top: -5px;
}
.life_feature {
  display: flex;
  align-content: center;
  font-size: 10px;
}
.life_feature > .line {
  width: 20px;
  height: 0.6px;
  margin: auto 8px;
  background-color: #f18101;
}
.life_content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 80px 0;
}
.life_content_item {
  flex: 1;
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  align-content: center;
  border-right: 1px solid #dedfe0;
}
.life_content_item > img {
  margin: 0 auto;
  width: 50px;
  height: 50px;
}
.content_item_tit {
  font-size: 13px;
  color: #353638;
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin: 10px 0;
}
.content_item_text {
  color: #353638;
  font-size: 7px;
}
.features_box {
  width: 100%;
  display: flex;
}
.features_item {
  display: flex;
  /* justify-content: center; */
  align-content: center;
  width: 33.33%;
}
.features_item_left > img {
  height: 60px;
}
.features_item_right {
  margin-left: 20px;
  padding: 6px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  letter-spacing: 2px;
}
.item_right_tit {
  font-size: 10px;
  color: #353638;
  font-weight: 600;
}
.item_right_text {
  font-size: 7px;
  color: #353638;
}
/* 项目 */
.project_item_box {
  display: flex;
  justify-content: space-between;
}
.project_img {
  flex: 35;
}
.right_end {
  display: flex;
  justify-content: flex-end;
}
.project_img > img {
  width: 100%;
}
.project_content {
  flex: 65;
  display: flex;
  flex-direction: column;
  margin: auto 0;
  /* align-content: center; */
}
.project_text {
  width: 80%;
  color: #353638;
  font-size: 12px;
  letter-spacing: 2px;
  margin-top: 20px;
}
.project_moreBtn > img {
  width: 100px;
  margin-left: -8px;
  margin-top: 30px;
}
.download_box{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.download_item{
  display: flex;
  justify-content: center;
}
.download_item>img{
  width: 70%;
}
</style>